/* eslint-disable import/no-cycle */
/* eslint-disable consistent-return */
/* eslint-disable react/button-has-type */
import React from 'react';
import { MdOutlineCancel } from 'react-icons/md';

import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../contexts/ContextProvider';

import { Button } from '.';
import { Methods } from '../pages';
import '../pages/Zackat.css';

const Notification = () => {
  const { loading, data } = Methods('notifications', 'GET', '5');
  const usr = JSON.parse(localStorage.getItem('user'));
  const navigate = useNavigate();
  const {
    setIsClicked,
  } = useStateContext();

  if (!usr.loggedIn) {
    navigate('./login');
  }
  if (loading) {
    return;
  }
  function onNotificationClick() {
    setIsClicked('notification', false);
    navigate('/notificationevents');
  }
  return (
    <div className="nav-item absolute right-5 md:right-40 top-16 bg-white dark:bg-[#42464D] p-8 rounded-lg w-96 zackat-div" id="notification">
      <div className="flex justify-between items-center">
        <div className="flex gap-3">
          <p className="font-semibold text-lg dark:text-gray-200">Alerts</p>
        </div>
        <div className="mt-5">
          <button
            className="text-sm rounded-lg p-1 hover:bg-light-gra"
            onClick={onNotificationClick}
            style={{ color: '#58E2F7', backgroundColor: '#366976' }}
          >View all notifications
          </button>
        </div>
        <Button
          icon={<MdOutlineCancel />}
          color="rgb(153, 171, 180)"
          bgHoverColor="light-gray"
          size="2xl"
          borderRadius="50%"
        />
      </div>
      <div className="mt-5 ">
        {data?.map((item, index) => (
          <div key={index} className="flex items-center leading-8 gap-5 border-b-1 border-color p-3">
            <img className="rounded-full h-10 w-10" src={item.image} alt={item.message} />
            <div>
              <p className="font-semibold" style={{ color: item.color }}>{item.message}</p>
              <p className="text-gray-500 text-sm dark:text-gray-400"> {item.desc} </p>
            </div>
          </div>
        ))}

      </div>
    </div>
  );
};

export default Notification;

/* eslint-disable no-plusplus */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-cycle */
import React, { useRef } from 'react';
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Sort,
  Filter,
  Resize,
  Edit,
  Toolbar,
  Page,
  PdfExport,
  ExcelExport,
  ForeignKey,
} from '@syncfusion/ej2-react-grids';
import { UploaderComponent } from '@syncfusion/ej2-react-inputs';
import { Query } from '@syncfusion/ej2-data';
import { Header } from '../components';
import { Methods } from './Methods';
import armband from '../data/watchsketch.png';
import { invGrid } from '../data/dummy';
import './inventory.css';
import './Zackat.css';

const Inv = () => {
  const { REACT_APP_API_ENDPOINT } = process.env;
  const valuerules = { required: true };
  /*
  const batch = { text: 'Batch', tooltipText: 'Batch Mode Editing', prefixIcon: 'e-expand', id: 'batch' };
  */
  const toolbarOptions = ['Search', 'Add', 'Edit', 'Update', 'Delete', 'PdfExport', 'ExcelExport'];

  let grid = useRef(null);
  const { loading, data } = Methods('inventory', 'GET', '');
  let crudUpdate = false;
  let crudCreate = false;
  let crudDelete = false;
  const usr = JSON.parse(localStorage.getItem('user'));

  if (loading) {
    return <p>Loading... </p>;
  }
  if (data.role && data.role.inventory && data.role.inventory.permissions.includes('Create')) {
    crudCreate = true;
  }
  if (data.role && data.role.inventory && data.role.inventory.permissions.includes('Update')) {
    crudUpdate = true;
  }
  if (data.role && data.role.inventory && data.role.inventory.permissions.includes('Delete')) {
    crudDelete = true;
  }
  if (data.role && data.role.inventory && !data.role.inventory.permissions.includes('Read')) {
    return <p>Not authorized...</p>;
  }
  const editing = {
    allowEditing: crudUpdate,
    allowAdding: crudCreate,
    allowDeleting: crudDelete,
    showConfirmDialog: false,
    showDeleteConfirmDialog: true,
    allowEditOnDblClick: true,
    mode: 'Dialog',
  };
  const actionComplete = (args) => {
    const editedData = args.data;
    /** Set initial Focus */
    if (args.requestType === 'save' && args.form && args.action === 'add') {
      if (args.data && args.data.device.length > 0) {
        // Here you can send the updated data to your server using AJAX call
        editedData.simstatus = { status: 'active' };
        fetch(`${REACT_APP_API_ENDPOINT}/inventory`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWTToken ${usr.token}`,
          },
          body: JSON.stringify(editedData),
        })
          .then((response) => response.json())
          .then((responseJson) => {
            // The default edit operation is cancelled
            args.cancel = true;
            // The added/edited data will be saved in the Grid
            grid.endEdit();
          })
          .catch((error) => {
            console.error(error);
          });
      }
    } else if (args.requestType === 'save' && args.form && args.action === 'edit') {
      if (args.data) {
        const invs = [];
        invs.push(editedData);
        // Here you can send the updated data to your server using AJAX call
        fetch(`${REACT_APP_API_ENDPOINT}/inventory`, {
          method: 'UPDATE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWTToken ${usr.token}`,
          },
          body: JSON.stringify(invs),
        })
          .then((response) => response.json())
          .then((responseJson) => {
            // The default edit operation is cancelled
            args.cancel = true;
            // The added/edited data will be saved in the Grid
            grid.endEdit();
          })
          .catch((error) => {
            console.error(error);
          });
      }
    } else if (args.requestType === 'batchsave') {
      if (args.rows && args.rows.length > 0) {
        const invs = [];
        for (let x = 0; x < args.rows.length; x++) {
          invs.push(args.rows[x].data);
        }
        // The default edit operation is cancelled

        // Here you can send the updated data to your server using AJAX call
        fetch(`${REACT_APP_API_ENDPOINT}/inventory`, {
          method: 'UPDATE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWTToken ${usr.token}`,
          },
          body: JSON.stringify(invs),
        })
          .then((response) => response.json())
          .then((responseJson) => {
            // The added/edited data will be saved in the Grid
            args.Cancel = true;
            grid.endEdit();
          })
          .catch((error) => {
            console.error(error);
          });
      }
    } else if (args.requestType === 'delete') {
      let entry = ' this entry?';
      if (args.data.length > 1) {
        entry = ' these entries?';
      }
      if (args.data) {
        // Here you can send the updated data to your server using AJAX call
        fetch(`${REACT_APP_API_ENDPOINT}/inventory`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWTToken ${usr.token}`,
          },
          body: JSON.stringify(editedData),
        })
          .then((response) => response.json())
          .then((responseJson) => {
            // The added/edited data will be saved in the Grid
            // The default edit operation is cancelled
            args.cancel = true;
            grid.endEdit();
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  };

  function onFileUpload(args) {
    // add addition data as key-value pair.
    console.log('onFileUpload', args);
    args.customFormData = [{ organizationid: data.user.organizationid }];
  }
  let uploadObj;
  const path = {
    removeUrl: `${REACT_APP_API_ENDPOINT}/inventoryremove`,
    saveUrl: `${REACT_APP_API_ENDPOINT}/inventoryupload`,
  };

  const FilterOptions = {
    type: 'Menu',
  };
  function completeUpload(args) {
    console.log('completeUpload', args);
  }
  const buttons = { browse: 'Choose File', clear: 'Clear All', upload: 'Upload All' };
  const currentThemeColor = localStorage.getItem('colorMode');
  const clickHandler = (args) => {
    if (grid && args.item.properties.text === 'PDF Export') {
      const properties = {
        fileName: `PDFExport_Inventory_${new Date().toISOString()}.pdf`,
        pageOrientation: 'Landscape',
      };
      grid.pdfExport(properties);
    } else if (grid && args.item.properties.text === 'Excel Export') {
      const properties = {
        fileName: `ExcelExport_Inventory_${new Date().toISOString()}.xlsx`,
      };
      grid.excelExport(properties);
    } else if (grid && args.item.properties.text === 'Batch') {
      if (grid.properties.editSettings.properties.mode === 'Dialog') {
        grid.properties.editSettings.properties.mode = 'Batch';
      } else {
        grid.properties.editSettings.properties.mode = 'Dialog';
      }
    }
  };
  const orgRule = { required: true };
  const organizations = {
    params: {
      actionComplete: () => false,
      allowFiltering: true,
      dataSource: data.organization,
      fields: { value: '_id', text: 'title' },
      query: new Query(),
    },
  };
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl zackat-div">
      <img
        className="w-20 h-20"
        src={armband}
        alt="arm band"
      />
      <Header title="Inventory" />
      <div style={{
        backgroundColor: currentThemeColor,
        textAlign: 'right',
        marginTop: '-40px',
      }}
      >
        <div id="droparea">{crudCreate ? 'IMPORT' : ''}
          {crudCreate === true && (
          <UploaderComponent
            id="fileupload"
            type="file"
            ref={(uplaod) => { uploadObj = uplaod; }}
            asyncSettings={path}
            buttons={buttons}
            multiple={false}
            actionComplete={completeUpload}
            autoUpload={false}
            uploading={onFileUpload}
            allowedExtensions=".csv"
          />
          )}
        </div>
        <GridComponent
          dataSource={data.inventory}
          enableHover
          allowPaging
          pageSettings={{ pageCount: 5 }}
          toolbar={toolbarOptions}
          editSettings={editing}
          allowSorting
          allowResizing
          allowFiltering
          filterSettings={FilterOptions}
          actionComplete={actionComplete}
          toolbarClick={clickHandler}
          allowTextWrap
          allowPdfExport
          allowExcelExport
          ref={(g) => { grid = g; }}
        >
          <ColumnsDirective>
            <ColumnDirective type="checkbox" width="50" />
            <ColumnDirective
              field="device"
              headerText="Device"
              width="80"
              textAlign="Center"
              validationRules={valuerules}
              visible
            />
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            {invGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}

            <ColumnDirective
              field="organizationid"
              headerText="Organization"
              width="70"
              textAlign="Center"
              editType="dropdownedit"
              edit={organizations}
              validationRules={valuerules}
              visible
              foreignKeyField="_id"
              foreignKeyValue="title"
              dataSource={organizations.params.dataSource}
            />

          </ColumnsDirective>
          <Inject services={[Search, Page, Edit, Toolbar, Filter, Sort, Resize, PdfExport, ExcelExport, ForeignKey]} />
        </GridComponent>
      </div>
    </div>
  );
};
export default Inv;

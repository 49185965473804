/* eslint-disable consistent-return */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-cycle */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
// import { AiOutlineMenu } from 'react-icons/ai';
// import { RiNotification3Line } from 'react-icons/ri';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import axios from 'axios';
import { Notification, UserProfile } from '.';
import { useStateContext } from '../contexts/ContextProvider';
import { Methods } from '../pages';

const NavButton = ({ title, customFunc, icon, color, dotColor, badge }) => (
  <TooltipComponent content={title} position="BottomCenter">
    <button
      type="button"
      onClick={() => customFunc()}
      style={{ color }}
      className="relative text-xl rounded-full p-3 hover:bg-light-gray"
    >
      <span
        style={{ background: dotColor }}
        className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"
      />
      {icon}
    </button>
  </TooltipComponent>
);
const NavBadgeButton = ({ title, customFunc, icon, color, dotColor, badge }) => (
  <TooltipComponent content={title} position="BottomCenter">
    <button
      type="button"
      onClick={() => customFunc()}
      style={{ color }}
      className="relative text-xl rounded-full p-3 hover:bg-light-gray"
    >
      <span
        style={{ background: dotColor }}
        className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"
      />
      {/* Notification Badge */}
      <div className="margin fontSize">{icon}</div>
      <div className="badge-block">
        {/* Warning Colored Notification Badge */}
        <span className="e-badge e-badge-warning e-badge-notification e-badge-overlap">{badge}</span>
      </div>
    </button>
  </TooltipComponent>
);
export const orgData = [];

const Navbar = () => {
  const {
    currentColor,
    activeMenu,
    setActiveMenu,
    handleClick,
    isClicked,
    setScreenSize,
    screenSize,
  } = useStateContext();
  let orgObj;
  const selectedOrg = JSON.parse(localStorage.getItem('organization'));
  const [text, setText] = useState();
  const [value, setValue] = useState();
  const [badge, setBadge] = useState('0');
  const { loading, data } = Methods('user', 'GET', '');
  const usr = JSON.parse(localStorage.getItem('user'));
  const { REACT_APP_API_ENDPOINT } = process.env;

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  useEffect(() => {
    const getData = async () => {
      usr.limit = 100;
      const query = Object.entries(usr)
        .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
        .join('&');
      const uri = `${REACT_APP_API_ENDPOINT}/notifications?${query}`;
      const response = await axios({
        url: uri,
        method: 'GET',
        headers: {
          Authorization: `JWTToken ${usr.token}`,
          'Content-Type': 'application/json',
        },
      });
      if (response.data && response.data.length >= 100) {
        setBadge('99+');
      } else if (response.data && response.data.length >= 1) {
        setBadge(response.data.length);
      } else {
        setBadge(0);
      }
    };
    getData();
  }, [loading]);

  useEffect(() => {
    if (screenSize <= 900) {
      setActiveMenu(false);
    } else {
      setActiveMenu(true);
    }
  }, [screenSize]);

  const handleActiveMenu = () => {
    setActiveMenu(!activeMenu);
  };
  if (loading) {
    return;
  }
  // call the change event's function after initialized the component.
  let oitem = 'null';
  let otext = 'null';
  if (data.organization && data.organization.length > 0) {
    oitem = data.organization[0]._id;
    otext = data.organization[0].title;
  }
  function change(args) {
    setValue(args.itemData === null ? oitem : args.itemData.id);
    setText(args.itemData === null ? otext : args.itemData.text);
    localStorage.setItem('organization', JSON.stringify({
      id: args.itemData.id,
      name: args.itemData.text,
    }));
    window.location.reload();
  }
  orgData.push({ id: 'na', text: 'Select Parent Organization' });
  data.organization.forEach((org) => {
    if (org.children) {
      orgData.push({ id: org._id, text: org.title });
    }
  });
  return (
    <div className="flex justify-between p-2 md:ml-6 md:mr-6 relative" style={{ position: 'relative', zIndex: '2' }}>
      <NavButton title="Menu" customFunc={handleActiveMenu} color={currentColor} icon={!activeMenu ? <img src="./hamburger.png" alt="Menu" width="40px" height="40px" /> : <img src="./hamburgerleft.png" alt="Menu" width="40px" height="40px" />} />
      <div className="flex">
        <DropDownListComponent id="orgsel" ref={(orgs) => { orgObj = orgs; }} dataSource={orgData} fields={orgData} change={change} value={selectedOrg ? selectedOrg.name : orgData[0].name} placeholder="Select an Organization" />
        <NavBadgeButton
          title="Notification"
          customFunc={() => handleClick('notification')}
          color={currentColor}
          badge={badge}
          icon={<img src="./notifications.png" alt="notifications" width="40px" height="40px" />}
        />
        <TooltipComponent content="Profile" position="BottomCenter">
          <div
            className="flex items-center gap-2 cursor-pointer p-1 hover:bg-light-gray rounded-lg"
            onClick={() => handleClick('userProfile')}
          >
            <img
              className="rounded-full w-8 h-8"
              src={data.user.image}
              alt="user-profile"
            />
            <p>
              <span className="text-gray-400 text-14">Hi,</span>{' '}
              <span className="text-gray-400 font-bold ml-1 text-14">
                {data.user.first}
              </span>
            </p>
            <MdKeyboardArrowDown className="text-gray-400 text-14" />
          </div>
        </TooltipComponent>
        {isClicked.notification && (<Notification />)}
        {isClicked.userProfile && (<UserProfile />)}
      </div>
    </div>
  );
};

export default Navbar;

/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable array-callback-return */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { APIProvider, Map, Marker, InfoWindow, AdvancedMarker, Pin, useAdvancedMarkerRef } from '@vis.gl/react-google-maps';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import zackatPin from '../data/mappin.png';
import googleMap from '../data/google-maps.png';
import './Map.css';

export default function AMap({ dt }) {
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [refreshData, setRefreshData] = useState(true);
  const [locateData, setLocations] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [markers, setMarker] = useState([]);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const { REACT_APP_GOOGLE_MAPS_API_KEY } = process.env;
  const { REACT_APP_GOOGLE_MAPS_ID } = process.env;
  const [markerRef, amarker] = useAdvancedMarkerRef();
  const timerid = useRef();
  let map;
  const usr = JSON.parse(localStorage.getItem('user'));
  const selectedOrg = JSON.parse(localStorage.getItem('organization'));
  if (selectedOrg && usr) {
    usr.organizationid = selectedOrg.id;
  }

  let initialMarkers = [];
  /*
  const { associates } = dt.data;
  if (associates) {
    associates.map((associate) => {
      const exists = checkMarker(associate);
      if (associate && associate.latlng && exists === false) {
        const marker = {
          lat: associate.latlng.latitude,
          lng: associate.latlng.longitude,
          title: associate.Name,
          _id: associate._id,
          key: associate._id,
          info: `Title: ${associate.Title}`,
          image: associate.EmployeeImage,

        };
        initialMarkers.push(marker);
      }
    });
  }
  if (initialMarkers.length > 0) {
    setMarker(initialMarkers);
  }
  */
  async function getData() {
    const loc = window.location;
    if (loc.pathname !== '/associates') {
      timerid.current = clearInterval(timerid.current);
    }
    const query = Object.entries(usr)
      .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
      .join('&');
    if (timerid.current) {
      // const response = await axios.get(`${REACT_APP_API_ENDPOINT}/associates?${query}`)
      const response = await axios({
        url: `${REACT_APP_API_ENDPOINT}/associates?${query}`,
        method: 'get',
        headers: {
          Authorization: `JWTToken ${usr.token}`,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          setData(response.data);
          setLoadingData(false);
          initialMarkers = [];
          if (response.data && response.data.associates) {
            response.data.associates.map((event) => {
              // const exists = checkMarker(event);
              if (event && event.latlng && event.latlng.latitude !== 0 && event.latlng.longitude !== 0) {
                const marker = {
                  lat: event.latlng.latitude,
                  lng: event.latlng.longitude,
                  title: event.name,
                  _id: event._id,
                  info: `Title: ${event.jobtask}`,
                  image: event.employeeImage,
                };
                initialMarkers.push(marker);
              }
            });
            timerid.current = setInterval(getData, 30000);
            setMarker(initialMarkers);
          }
        })
        .catch((error) => console.log(error));
    }
  }

  // This will run one time after the component mounts
  useEffect(() => {
    // callback function to call when event triggers
    const onPageLoad = () => {
      timerid.current = setInterval(getData, 30000);
      setTimeout(getData, 500);
    };

    // Check if the page has already loaded
    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad, false);
      // Remove the event listener when component unmounts
      timerid.current = clearInterval(timerid.current);
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  function clearMarkers(markers) {
    markers = [];
  }
  function checkMarker(val) {
    return markers.some((item) => val._id === item._id);
  }
  function onGoogleMapLoad() {
    map.fitBounds();
  }
  if (loadingData && !refreshData) {
    return <p>Loading... </p>;
  }
  const [infoWindowShown, setInfoWindowShown] = useState(false);

  // clicking the marker will toggle the infowindow
  const handleMarkerClick = useCallback(
    () => setInfoWindowShown((isShown) => !isShown),
    [],
  );

  // if the maps api closes the infowindow, we have to synchronize our state
  const handleClose = useCallback(() => setInfoWindowShown(false), []);
  const currentThemeColor = localStorage.getItem('colorMode');

  return (
    <div style={{
      backgroundColor: currentThemeColor,
      textAlign: 'right',
      marginTop: '-40px',
    }}
    >
      <button
        className="button"
        type="button"
        style={{
          borderWidth: 1,
          borderColor: 'rgba(0,0,0,0.2)',
          alignItems: 'center',
          justifyContent: 'center',
          width: 30,
          height: 30,
          borderRadius: 100,
        }}
        onClick={() => setLocations(!locateData)}
      >
        <img src={googleMap} alt="map" />
      </button>
      <APIProvider apiKey={`${REACT_APP_GOOGLE_MAPS_API_KEY}`}>
        {locateData && (
          <Map
            style={{ width: '100%', height: '100vh' }}
            defaultCenter={{ lat: markers[0].lat, lng: markers[0].lng }}
            mapId={`${REACT_APP_GOOGLE_MAPS_ID}`}
            defaultZoom={10}
                    /* gestureHandling={'greedy'} */
                    /* disableDefaultUI={false} */
                    /* onLoad={onGoogleMapLoad} */
            onGoogleMapLoad={() => onGoogleMapLoad()}
          >
            {markers.map((marker) => (
              <AdvancedMarker
                className="bounce"
                key={marker._id}
                onClick={() => handleMarkerClick()}
                position={marker}
                ref={markerRef}

              >
                <img src={zackatPin} width="60px" height="60px" />
                {/*
                <Pin
                  className="bounce"
                  background={currentThemeColor}
                  borderColor="#000000"
                  glyphColor="tranparent"
                  title={marker.title}
                >
                  <div style={{
                    color: currentThemeColor,
                  }}
                  >
                    <img src={marker.image} width="15px" height="20px" />
                  </div>
                </Pin>
                */}
                {infoWindowShown && (
                  <InfoWindow
                    position={marker}
                    onClose={() => handleClose()}
                  >
                    <h2>Device: {marker.title}</h2>
                    <p>{marker.info}</p>
                    <div>
                      <h1>{marker.title}</h1>
                    </div>

                  </InfoWindow>
                )}
              </AdvancedMarker>
            ))}
          </Map>
        )}
      </APIProvider>
    </div>
  );
}

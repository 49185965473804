/* eslint-disable no-alert */
/* eslint-disable react/button-has-type */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-cycle */
import React, { useState } from 'react';
import { MdOutlineCancel } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import { DialogUtility } from '@syncfusion/ej2-react-popups';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';

import { Button } from '.';
import { useStateContext } from '../contexts/ContextProvider';
import { Methods } from '../pages';
import { userProfileData } from '../data/dummy';

const UserProfile = () => {
  const { currentColor } = useStateContext();
  const { REACT_APP_API_ENDPOINT } = process.env;
  const props = JSON.parse(localStorage.getItem('user'));
  let loggedIn = false;
  const {
    setIsClicked,
  } = useStateContext();
  const pages = [
    { id: 'dashboard', text: 'Dashboard' },
    { id: 'reports', text: 'Reports' },
    { id: 'organizations', text: 'Organizations' },
    { id: 'users', text: 'Users' },
    { id: 'roles', text: 'Roles' },
    { id: 'associates', text: 'Associates' },
    { id: 'alerts', text: 'Alerts' },
    { id: 'inventory', text: 'Inventory' },
    { id: 'card', text: 'Card' },
    { id: 'calendar', text: 'Calendar' },
  ];
  const fields = { text: 'text', value: 'id' };

  const [smsChecked, setSmsChecked] = useState(false);
  const [emailChecked, setEmailChecked] = useState(false);
  const [address, setAddress] = useState('');
  const [address1, setAddress1] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  if (props) {
    loggedIn = props.loggedIn;
    // setEmail(props.email);
  }
  const navigate = useNavigate();
  const handleSubmit = () => {
    localStorage.removeItem('user');

    window.location.assign('/login');
  };

  const { loading, data } = Methods('user', 'GET', '');
  if (loading) {
    return <p>Loading... </p>;
  }
  const handleChange = (event) => {
    // 👇️ this is the checkbox itself user
    if (event.target.id === 'optinsms') {
      setSmsChecked(event.target.checked);
      // 👇️ this is the checked value of the field
      data.user.smsOptin = event.target.checked;
    } else if (event.target.id === 'optinemail') {
      setEmailChecked(event.target.checked);
      // 👇️ this is the checked value of the field
      data.user.emailOptin = event.target.checked;
    }
    console.log(event.target.checked, event.target.value, data.user);
    fetch(`${REACT_APP_API_ENDPOINT}/user`, {
      method: 'UPDATE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWTToken ${props.token}`,
      },
      body: JSON.stringify(data.user),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        data.user.emailOptin = responseJson.emailOptin;
        data.user.smsOptin = responseJson.smsOptin;
        // The added/edited data will be saved in the Grid
      })
      .catch((error) => {
        console.error(error);
      });
  };
  function okClick() {
    setIsClicked('notification', false);

    const curpwd = document.getElementById('cpwd').value;
    const newpwd = document.getElementById('npwd').value;
    const confirmpwd = document.getElementById('fpwd').value;

    if (newpwd === '' || confirmpwd === '') {
      alert(`Please enter matching new and confirm passwords ${newpwd} ${confirmpwd} ${confirmpwd}`);
      return;
    }

    if (curpwd.length < 7 || newpwd.length < 7) {
      alert('The new password must be 8 characters or longer');
      return;
    }
    if (newpwd !== confirmpwd) {
      alert('New password does not match confirm password.');
      return;
    }
    props.newPassword = newpwd;
    props.password = curpwd;

    fetch(`${REACT_APP_API_ENDPOINT}/user`, {
      method: 'UPDATE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWTToken ${props.token}`,
      },
      body: JSON.stringify(props),

    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson && responseJson.response) {
          alert(responseJson.response);
        } else {
          alert('Your password has been updated');
        }
        // The added/edited data will be saved in the Grid
      })
      .catch((error) => {
        console.error(error);
      });
    // Hide the dialog
    this.hide();
  }
  const selectLanding = (event) => {
    data.user.landingPage = event.target.itemData.id;

    // Remove the New Password to avoid creating a new password
    data.user.newPassword = '';
    // Avoid Password comparison
    data.user.password = '';
    fetch(`${REACT_APP_API_ENDPOINT}/user`, {
      method: 'UPDATE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWTToken ${props.token}`,
      },
      body: JSON.stringify(data.user),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        // The added/edited data will be saved in the Grid
      })
      .catch((error) => {
        console.error(error);
      });
  };
  function okProfileClick() {
    data.user.address = document.getElementById('address').value;
    data.user.address1 = document.getElementById('address1').value;
    data.user.city = document.getElementById('city').value;
    data.user.state = document.getElementById('state').value;
    data.user.zip = document.getElementById('zip').value;
    data.user.email = document.getElementById('email').value;
    data.user.mobile = document.getElementById('mobile').value;
    // Remove the New Password to avoid creating a new password
    data.user.newPassword = '';
    setIsClicked('notification', false);
    fetch(`${REACT_APP_API_ENDPOINT}/user`, {
      method: 'UPDATE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWTToken ${props.token}`,
      },
      body: JSON.stringify(data.user),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        // The added/edited data will be saved in the Grid
      })
      .catch((error) => {
        console.error(error);
      });

    // Hide the dialog
    this.hide();
  }
  function cancelClick() {
    // alert('you clicked Cancel button')
    this.hide();
  }
  function buttonClick() {
    setIsClicked('profile', false);
    DialogUtility.confirm({
      title: ' Change Password Dialog',
      content: '<p>Current password:</p><input type="password" id="cpwd"  name="Required" class="e-input" placeholder="Type here.." />'
        + '<p>New password:</p><input type="password" id="npwd" name="Required" class="e-input" placeholder="Type here.." />'
        + '<p>Current password:</p><input type="password" id="fpwd"  name="Required" class="e-input" placeholder="Type here.." />',
      okButton: { text: 'OK', click: okClick },
      cancelButton: { text: 'Cancel', click: cancelClick },
      showCloseIcon: true,
      closeOnEscape: true,
      isModal: true,
      animationSettings: { effect: 'Zoom' },
    });
  }

  function profileClick() {
    setIsClicked('profile', false);
    DialogUtility.confirm({
      title: ' Use Profile Dialog',
      content: '<p>Address:</p><input type="text" id="address"  name="address" class="e-input" placeholder="Type address here.." />'
        + '<p>Address cont. :</p><input type="text" id="address1" name="address1" class="e-input" placeholder="Type address here.." />'
        + '<p>City:</p><input type="text" id="city" name="city" class="e-input" placeholder="Type city here.." />'
        + '<p>State:</p><input type="text" id="state" name="state" class="e-input" placeholder="Type state here.." />'
        + '<p>Zip:</p><input type="text" id="zip" name="zip" class="e-input" placeholder="Type state here.." />'
        + '<p>Email:</p><input type="text" id="email" name="email" class="e-input" placeholder="Type email address here.." />'
        + '<p>Mobile:</p><input type="text" id="mobile" name="mobile" class="e-input" placeholder="Type mobile number here.." />',
      okButton: { text: 'OK', click: okProfileClick },
      cancelButton: { text: 'Cancel', click: cancelClick },
      showCloseIcon: true,
      closeOnEscape: true,
      isModal: true,
      animationSettings: { effect: 'Zoom' },
    });
    document.getElementById('address').value = data.user.address;
    document.getElementById('address1').value = data.user.address1;
    document.getElementById('city').value = data.user.city;
    document.getElementById('state').value = data.user.state;
    document.getElementById('zip').value = data.user.zip;
    document.getElementById('email').value = data.user.email;
    document.getElementById('mobile').value = data.user.mobile;
  }
  return (
    <div className="nav-item absolute right-1 top-16 bg-white dark:bg-[#42464D] p-8 rounded-lg w-96 zackat-div" id="profile">
      <div className="flex justify-between items-center">
        <p className="font-semibold text-lg dark:text-gray-200 zac">User Profile</p>
        <Button
          icon={<MdOutlineCancel />}
          color="rgb(153, 171, 180)"
          bgHoverColor="light-gray"
          size="2xl"
          borderRadius="50%"
        />
      </div>
      <div className="flex gap-5 items-center mt-6 border-color border-b-1 pb-6">
        <img
          className="rounded-full h-24 w-24"
          src={data.user.image}
          alt="user-profile"
        />
        <div>
          <p className="font-semibold text-xl dark:text-gray-200"> {data.user.first} {data.user.last} </p>
          <p className="text-gray-500 text-sm dark:text-gray-400"> Administrator </p>
          <p className="text-gray-500 text-sm font-semibold dark:text-gray-400"> {data.user.email} </p>
          <div>
            <DropDownListComponent id="ddlelement" dataSource={pages} placeholder="Select a landing page" value={data.user.landingPage} fields={fields} onChange={selectLanding} />
          </div>
        </div>
      </div>
      <div>
        {userProfileData.map((item, index) => (
          <div
            key={index}
            className="flex gap-5 border-b-1 border-color p-4 hover:bg-light-gray cursor-pointer  dark:hover:bg-[#42464D]"
          >
            <button
              className="text-sm rounded-lg p-1 hover:bg-light-gra"
              style={{ color: item.iconColor, backgroundColor: item.iconBg }}
              onClick={buttonClick}
              id="targetButton"
            >
              Change Password ↵
            </button>
            <button
              className="text-sm rounded-lg p-1 hover:bg-light-gra"
              style={{ color: item.iconColor, backgroundColor: item.iconBg }}
              onClick={profileClick}
              id="profileButton"
            >
              Profile ↵
            </button>
            <div
              className="zackat-div"
              style={{ width: '200px', height: '100px' }}
            >
              <legend>&nbsp;&nbsp;OptIn</legend>
              &nbsp;&nbsp;SMS<input
                type="checkbox"
                id="optinsms"
                value={data.user.smsOptin}
                onChange={handleChange}
              />
              <br />
              &nbsp;&nbsp;Email<input
                type="checkbox"
                id="optinemail"
                value={data.user.emailOptin}
                onChange={handleChange}
              />
            </div>
          </div>
        ))}
      </div>
      <div className="mt-5">
        <button
          type="button"
          onClick={() => handleSubmit()}
          title="Logout"
          style={{ backgroundColor: currentColor, color: 'white', borderRadius: '10px' }}
          className="text-xl p-3 w-full hover:drop-shadow-xl"
        >
          Logout ↵
        </button>
        {/* {loggedIn ? <div>Your email address is {email}</div> : <div/>} */}
      </div>
    </div>

  );
};

export default UserProfile;

/* eslint-disable no-unused-vars */
/* eslint-disable no-dupe-keys */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { FiSettings } from 'react-icons/fi';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

import {
  Forgot, Navbar, Footer, Sidebar, ThemeSettings, BasicTabs, FlipOrig, ResetPassword, Recordings, AssociateSchedule, Events,
} from './components';
import Home from './pages/home';
import Login from './pages/login';

import {
  Ecommerce,
  Organizations,
  Alerts,
  Dashboard,
  Calendar,
  Associates,
  Inventory,
  Inv,
  Stacked,
  Pyramid,
  Users,
  Kanban,
  Line,
  Area,
  Bar,
  Pie,
  Financial,
  ColorPicker,
  ColorMapping,
  Editor,
  Reports,
  NotificationEvents,
  Roles,
} from './pages';

import './App.css';
import { useStateContext } from './contexts/ContextProvider';

const App = () => {
  const {
    setCurrentColor,
    setCurrentMode,
    currentMode,
    activeMenu,
    currentColor,
    themeSettings,
    setThemeSettings,
  } = useStateContext();
  const [loggedIn, setLoggedIn] = useState(false);
  const [email, setEmail] = useState('');
  const { REACT_APP_API_ENDPOINT } = process.env;
  const usr = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    const currentThemeColor = localStorage.getItem('colorMode');
    const currentThemeMode = localStorage.getItem('themeMode');
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    } else {
      setCurrentColor('#FF4A00');
      setCurrentMode('Light');
    }
  }, []);

  useEffect(() => {
    // Fetch the user email and token from local storage
    const user = JSON.parse(localStorage.getItem('user'));
    // If the token/email does not exist, mark the user as logged out
    if (!user || !user.token) {
      setLoggedIn(false);
      return;
    }
    // If the token exists, verify it with the auth server to see if it is valid
    fetch(`${REACT_APP_API_ENDPOINT}/verify`, {
      method: 'POST',
      headers: {
        Authorization: `JWTToken ${usr.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: user.email,
        token: user.token,
        _id: user._id,
        organizationid: user.organizationid,
      }),
    })
      .then((r) => r.json())
      .then((r) => {
        setLoggedIn(r.message === 'success');
        localStorage.setItem('user', JSON.stringify({
          _id: r._id,
          loggedIn: (r.message === 'success'),
          message: r.message,
          email: r.email,
          token: r.token,
          organizationid: r.organizationid,
        }));
        setEmail(user.email || '');
      });
  }, []);
  const INTERVAL_TIME = 1000 * 60 * 60;

  const events = [
    'mousedown',
    'mousemove',
    'wheel',
    'keydown',
    'touchstart',
    'scroll',
    // ... add other events here ...
  ];

  const addListeners = (eevents, cb) => {
    eevents.forEach((event) => window.addEventListener(event, cb, { passive: true }));
    return () => {
      events.forEach((event) => window.removeEventListener(event, cb, { passive: true }));
    };
  };
  const [tabNo, setTabNo] = useState(0);
  const useRefreshTokenInterval = (activeAccount, interval = INTERVAL_TIME) => {
    const intervalRef = React.useRef();
    const unlistenRef = React.useRef();
    const wasActiveRef = React.useRef(false);

    React.useEffect(() => {
      const refreshTokenInterval = (initial) => {
        if (!initial && !wasActiveRef.current) {
          // Inactive, clearing interval, not refreshing token
          // CookieService.remove(activeAccount);
          localStorage.removeItem('user');
          localStorage.removeItem('activeMenu');
          if (unlistenRef.current) {
            unlistenRef.current();
          }
          clearInterval(intervalRef.current);
          window.location.assign('/login');
        }
        try {
          const isLoggedIn = loggedIn;
          if (isLoggedIn) {
            // Attempting token refresh
            console.log('Refresh token');
            // AuthenticationService.refresh(null, (response) =>
            //  CookieService.set(activeAccount, response.data)
            // );
          }
        } catch (error) {
          console.log(error);
        } finally {
          // Reset activity for next interval
          wasActiveRef.current = false;
        }
      };

      refreshTokenInterval(true); // <-- initial call

      // Setup token refresh interval
      intervalRef.current = setInterval(refreshTokenInterval, interval);

      // Add activity event listeners
      unlistenRef.current = addListeners(events, () => {
        // There was some activity
        wasActiveRef.current = true;
      });

      // Return cleanup function to clear any running interval timer
      // and unsubscribes event listeners
      return () => {
        clearInterval(intervalRef.current);
        if (unlistenRef.current) {
          unlistenRef.current();
        }
      };
    }, [activeAccount, interval]);
  };
  useRefreshTokenInterval('');
  const blank = '';
  return (
    <div className={currentMode === 'Dark' ? 'dark' : ''}>
      <BrowserRouter>
        <div className="flex relative dark:bg-main-dark-bg">
          {loggedIn && activeMenu ? (
            <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white ">
              {loggedIn ? (<Sidebar />) : (<div>{ blank }</div>)}
            </div>
          ) : (
            <div className="w-0 dark:bg-secondary-dark-bg">
              {loggedIn ? (<Sidebar />) : (<div>{ blank }</div>)}
            </div>
          )}
          <div
            className={
              activeMenu
                ? 'dark:bg-main-dark-bg  bg-main-bg min-h-screen md:ml-72 w-full  '
                : 'bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 '
            }
          >
            <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">
              {loggedIn && <Navbar />}
            </div>
            <div>
              {themeSettings && (<ThemeSettings />)}

              <Routes>
                {/* dashboard  */}
                {/* <Route path="/" element={(<Ecommerce />)} /> */}

                <Route
                  path="/"
                  element={<Home email={email} loggedIn={loggedIn} setLoggedIn={setLoggedIn} />}
                />
                <Route path="/login" element={<Login setLoggedIn={setLoggedIn} setEmail={setEmail} />} />

                <Route
                  path="/billing"
                  element={(<Ecommerce setLoggedIn={setLoggedIn} setEmail={setEmail} />)}
                />

                {/* pages  */}
                <Route path="/organizations" element={<Organizations />} />

                <Route path="/alerts" element={<Alerts />} />
                <Route path="/dashboard" element={<Dashboard />} />

                <Route path="/associates" element={<Associates />} />
                <Route path="/users" element={<Users />} />
                <Route path="/roles" element={<Roles />} />
                <Route path="/events" element={<Events />} />
                <Route path="/notificationevents" element={<NotificationEvents />} />
                <Route path="/basic" element={<BasicTabs tabNo={tabNo} />} />
                <Route path="/cardflip" element={<FlipOrig />} />

                <Route path="/reports" element={<Reports />} />
                <Route path="/inventory" element={<Inv />} />
                <Route path="/card" element={<Inventory />} />
                <Route path="/forgot" element={<Forgot />} />
                <Route exact path="/resetPassword" element={<ResetPassword />} />

                {/* apps  */}
                <Route path="/kanban" element={<Kanban />} />
                <Route path="/editor" element={<Editor />} />
                <Route path="/calendar" element={<Calendar />} />
                <Route path="/color-picker" element={<ColorPicker />} />

                {/* charts  */}
                <Route path="/line" element={<Line />} />
                <Route path="/area" element={<Area />} />
                <Route path="/bar" element={<Bar />} />
                <Route path="/pie" element={<Pie />} />
                <Route path="/financial" element={<Financial />} />
                <Route path="/color-mapping" element={<ColorMapping />} />
                <Route path="/pyramid" element={<Pyramid />} />
                <Route path="/stacked" element={<Stacked />} />
                <Route path="/recordings" element={<Recordings />} />
                <Route path="/associateschedule" element={<AssociateSchedule />} />

              </Routes>
            </div>
            <Footer />
          </div>
        </div>
      </BrowserRouter>
    </div>
  );
};

export default App;

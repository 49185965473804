/* eslint-disable no-alert */
/* eslint-disable import/no-cycle */
import React, { useRef } from 'react';
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Page,
  Selection, Toolbar, Edit, Sort, Filter,
  ColumnChooser,
  Resize,
} from '@syncfusion/ej2-react-grids';

import { notificationsGrid } from '../data/dummy';
import { Header } from '../components';
import { Methods } from './Methods';
import './Zackat.css';

const NotificationEvents = () => {
  let grid = useRef(null);
  const details = { text: 'Ack', tooltipText: 'Acknowledge ', prefixIcon: 'e-expand', id: 'ack' };
  const toolbarOptions = ['Delete', 'Search', details];
  const { REACT_APP_API_ENDPOINT } = process.env;

  const editing = { allowDeleting: false, allowEditing: false };
  const { loading, data } = Methods('notifications', 'GET', '1000');

  if (loading) {
    return <p>Loading... </p>;
  }
  const clickHandler = (args) => {
    if (grid && args.item.properties.text === 'Ack') {
      const selectedRecords = grid.getSelectedRecords();
      if (!selectedRecords || selectedRecords.length === 0) {
        alert('Please select a notification');
      } else if (selectedRecords && selectedRecords.length >= 1) {
        const usr = JSON.parse(localStorage.getItem('user'));
        const notifications = [];
        const user = { user: usr };
        notifications.notifications = selectedRecords;
        const output = { ...user, ...notifications };

        fetch(`${REACT_APP_API_ENDPOINT}/notifications`, {
          method: 'UPDATE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWTToken ${usr.token}`,
          },
          body: JSON.stringify(output),
        })
          .then((response) => response.json())
          .then((responseJson) => {
            // The added/edited data will be saved in the Grid
            console.log(responseJson);
            window.location.reload();
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        alert('Please select a notification');
      }
    }
  };
  const FilterOptions = {
    type: 'Menu',
  };
  console.log('Notifications', data);
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl zackat-div">
      <Header category="" title="Notification Events" />
      <GridComponent
        dataSource={data}
        width="auto"
        allowPaging
        allowSorting
        toolbarClick={clickHandler}
        editSettings={editing}
        toolbar={toolbarOptions}
        enableHover={false}
        pageSettings={{ pageSize: 25, pageCount: 10 }}
        allowResizing
        allowFiltering
        filterSettings={FilterOptions}
        showColumnChooser
        allowTextWrap
        ref={(g) => { grid = g; }}
      >
        <ColumnsDirective>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {notificationsGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}
        </ColumnsDirective>
        <Inject services={[Search, Page, Selection, Toolbar, Edit, Sort, Filter, ColumnChooser, Resize]} />

      </GridComponent>
    </div>
  );
};
export default NotificationEvents;
